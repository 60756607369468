<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Logout",

  methods: {
    ...mapActions("tag", ["initializeOpenPanels"]),
  },

  async created() {
    window.localStorage.clear();
    this.$router.push("/login");
    this.initializeOpenPanels();
  },
};
</script>

<style></style>
